import { udtechServerMode } from "./consts"

export const pushToDataLayer = (data: any) => {

  console.log(data.event, data)
  // @ts-ignore
  if (window && window.dataLayer) {
    // @ts-ignore
    window.dataLayer.push({
      ...data,
    })
  }
}
