import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['user'] = {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  country: '',
  is_vip: 0,
  zip_code: '',
  confirm_code: '',
  link_code: '',
  confirmType: 'phone',
  files: null,
  photo_id_status: null,
  proof_of_address_status: null,
  userLocation: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action) => ({ ...state, ...action.payload }),
    resetUser: () => initialState,
    setFirstName: (state, action: PayloadAction<IStore['user']['first_name']>) => ({
      ...state,
      first_name: action.payload,
    }),
    setConfirmType: (state, action: PayloadAction<IStore['user']['confirmType']>) => ({
      ...state,
      confirmType: action.payload,
    }),
    setLastName: (state, action: PayloadAction<IStore['user']['last_name']>) => ({
      ...state,
      last_name: action.payload,
    }),
    setEmail: (state, action: PayloadAction<IStore['user']['email']>) => ({
      ...state,
      email: action.payload,
    }),
    setPhone: (state, action: PayloadAction<IStore['user']['phone']>) => ({
      ...state,
      phone: action.payload,
    }),
    setCountry: (state, action: PayloadAction<IStore['user']['country']>) => ({
      ...state,
      country: action.payload,
    }),
    setZipCode: (state, action: PayloadAction<IStore['user']['zip_code']>) => ({
      ...state,
      zip_code: action.payload,
    }),
    setConfirmCode: (state, action: PayloadAction<IStore['user']['confirm_code']>) => ({
      ...state,
      confirm_code: action.payload,
    }),
    setLinkCode: (state, action: PayloadAction<IStore['user']['link_code']>) => ({
      ...state,
      link_code: action.payload,
    }),
  },
})

export const {
  setFirstName,
  setLastName,
  setPhone,
  setCountry,
  setZipCode,
  updateUser,
  resetUser,
  setEmail,
  setConfirmType,
  setConfirmCode,
  setLinkCode,
} = userSlice.actions

export default userSlice.reducer
