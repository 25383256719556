import { IOffice } from "../store/types"

export const ApiRoutes = {
  postSellData: '/post-sell-data',
  offices: '/offices',
  booking: '/booking',
  profile: '/profile',
  offer: '/offer',
  track: '/track',
  term: '/term',
  certInfo: '/cert-info',
  emailExisting: '/check-email',
  subscribe: '/subscribe',
  login: '/login',
  sendResetLink: '/password/reset',
  resetPassword: '/password/change',
  profileResetPassword: '/profile/password',
  profilePayment: '/profile/payment',
  plaidData: '/plaid/data',
  plaidToken: '/plaid/access-token',
  itemParams: '/dicts',
  profileParams: '/dicts/profile',
  offers: '/offers',
  appointments: '/appointments',
  appointment: '/appointment',
  offersIds: '/offers/ids',
  register: '/register',
  terms: '/term',
  termsDecline: '/term/decline',
  termsAccept: '/term/accept',
  offerFinal: '/offer/final-offer',
  file: '/file',
  fedexReceipt: '/fedex-receipt',
  shippingKitCheckIn: '/shipping-check-in',
  officePage: '/office-page',
  officePreview: '/office-page/preview',
  deals: '/deals',
  dealsIds: '/deals/ids',
  incompleteRequests: '/incomplete-requests',
  page: '/page',
  unsubscribe: '/unsubscribe',
  appointmentConfirmation: '/appointment/confirmation',
  getDates: function (countryCode: string) {
    return `${this.booking}/${countryCode}/dates`
  },
  getTimes: function (countryCode: string) {
    return `${this.booking}/${countryCode}/times`
  },
  getTimeSlots: function (officeId: IOffice['id']) {
    return `/office/${officeId}/slots`
  },
  createAppointment: function (countryCode: string) {
    return `${this.booking}/${countryCode}/appointments`
  },
  cancelAppointment: function (id: number) {
    return `${this.appointment}/${id}/cancel`
  },
  rescheduleAppointment: function (id: number) {
    return `${this.appointment}/${id}/reschedule`
  },
  auth: function (isEmail: boolean, isCode?: boolean) {
    return `/passwordless/${isEmail ? 'email' : 'phone'}${isCode ? '/code' : ''}`
  },
}
