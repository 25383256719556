// @ts-nocheck
import auth0 from 'auth0-js'
import { callApi } from './callApi'
import { ApiRoutes } from './apiRoutes'
import { LOCAL_ACCESS_TOKEN, LOCAL_ID_TOKEN } from './consts'
import { navigate } from 'gatsby-plugin-intl'
import * as Sentry from '@sentry/browser'
import { IRedirectData } from '../types/redirectTypes'

const isBrowser = typeof window !== 'undefined'
const auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.GATSBY_AUTH0_DOMAIN,
      clientID: process.env.GATSBY_AUTH0_CLIENTID,
      redirectUri: process.env.GATSBY_AUTH0_CALLBACK,
      responseType: 'token id_token',
      scope: 'openid profile email',
      // audience: process.env.GATSBY_AUTH0_AUDIENCE,
    })
  : {}

export const tokens = {
  accessToken: false,
  idToken: false,
  expiresAt: false,
}

const checkTokenValid = async () => {
  console.log('checkTokenValid')
  let success = true
  try {
    const response = await callApi({ method: 'GET', url: ApiRoutes.profile })

    Sentry.setUser({ email: response.data.data.email, id: response.data.data.id })
    if (!response) success = false
  } catch (error) {
    success = false
  } finally {
    if (success) {
      localStorage.setItem('isLoggedIn', String(true))
    } else {
      localStorage.setItem('isLoggedIn', String(false))
      localStorage.removeItem(LOCAL_ID_TOKEN)
      // navigate('/login')
    }
  }
}

let user = {}

export const isAuthenticated = () => {
  if (!isBrowser) return

  return localStorage.getItem('isLoggedIn') === 'true'
}

export const login = () => {
  if (!isBrowser) return
  auth.authorize({ prompt: 'login' })
}

const setSession =
  (cb = () => {}) =>
  (err, authResult) => {
    console.log('setSession')
    if (err) {
      console.log(err, 'setSession err')
      navigate('/')
      cb()
      return
    }

    if (authResult && authResult.accessToken && authResult.idToken) {
      const expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
      tokens.accessToken = authResult.accessToken
      tokens.idToken = authResult.idToken
      tokens.expiresAt = expiresAt
      user = authResult.idTokenPayload
      localStorage.setItem('isLoggedIn', true)
      localStorage.setItem(LOCAL_ID_TOKEN, authResult.idToken)
      localStorage.setItem(LOCAL_ACCESS_TOKEN, authResult.accessToken)
      const fromLocation = sessionStorage.getItem('fromLocation')
      const fromCheckIn = localStorage.getItem('LoginFromCheckIn')
      const parsedData: IRedirectData | undefined = fromCheckIn ? JSON.parse(fromCheckIn) : undefined
      const redirectAfterLogin = localStorage.getItem('redirectAfterLogin')
      const redirectParsedData: IRedirectData | undefined = redirectAfterLogin
        ? JSON.parse(redirectAfterLogin)
        : undefined
      const isEsp =
        localStorage.getItem('gatsby-intl-language') && localStorage.getItem('gatsby-intl-language') === 'es'

      Sentry.setUser({
        email: authResult.idTokenPayload.email,
        id: +authResult.idTokenPayload.sub.replace('auth0|', ''),
      })

      if (fromLocation) {
        const parseLocation = JSON.parse(fromLocation) as Location
        sessionStorage.removeItem('fromLocation')
        navigate(
          (isEsp ? '/es' : '') +
            (isEsp ? parseLocation.pathname.replace('/es', '') : parseLocation.pathname) +
            parseLocation.search
        )
      } else if (fromCheckIn && parsedData && parsedData.redirect) {
        navigate((isEsp ? '/es' : '') + parsedData.path)
      } else if (redirectAfterLogin && redirectParsedData && redirectParsedData.redirect) {
        navigate((isEsp ? '/es' : '') + redirectParsedData.path)
        localStorage.removeItem('redirectAfterLogin')
      } else {
        navigate(`${isEsp ? '/es' : ''}/my-account/`)
      }

      cb()
    }
  }

export const silentAuth = (callback) => {
  console.log('silentAuth')
  if (!isAuthenticated()) return callback()
  checkTokenValid()
  callback()
}

export const handleAuthentication = () => {
  console.log('handleAuthentication')
  if (!isBrowser) return

  auth.parseHash(setSession())
}

export const getProfile = () => user

export const logout = () => {
  localStorage.setItem('isLoggedIn', String(false))
  localStorage.removeItem(LOCAL_ID_TOKEN)
  auth.logout()

  const isEsp = localStorage.getItem('gatsby-intl-language') && localStorage.getItem('gatsby-intl-language') === 'es'
  if (isBrowser) window.location.href = isEsp ? '/es/' : '/'
}
