import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['errors'] = {
  otherError: '',
  emailError: '',
  codeError: '',
  phoneError: '',
  appointmentError: '',
  offerError: '',
  getOfficesError: '',
  getDatesError: '',
  getTimesError: '',
  loginError: '',
  resetPassword: '',
  getItemParamsError: '',
  createUserError: '',
  updateUserError: '',
  profileResetPassError: '',
  shippingKitCheckInError: '',
  bulkError: '',
  appointmentItemsError: '',
  getDealError: '',
  getProfileParamsError: '',
}

export const errors = createSlice({
  name: 'error',
  initialState,
  reducers: {
    showOtherError: (state, action) => {
      return { ...state, otherError: action.payload }
    },
    hideOtherError: (state) => {
      return { ...state, otherError: '' }
    },
    showEmailError: (state, action) => {
      return { ...state, emailError: action.payload }
    },
    hideEmailError: (state) => {
      return { ...state, emailError: '' }
    },
    showLoginError: (state, action) => {
      return { ...state, loginError: action.payload }
    },
    hideLoginError: (state) => {
      return { ...state, loginError: '' }
    },
    showPhoneError: (state, action) => {
      return { ...state, phoneError: action.payload }
    },
    hidePhoneError: (state) => {
      return { ...state, phoneError: '' }
    },
    showCodeError: (state, action) => {
      return { ...state, codeError: action.payload }
    },
    hideCodeError: (state) => {
      return { ...state, codeError: '' }
    },
    showAppointmentError: (state, action) => {
      return { ...state, appointmentError: action.payload }
    },
    hideAppointmentError: (state) => {
      return { ...state, appointmentError: '' }
    },
    showOfferError: (state, action) => {
      return { ...state, offerError: action.payload }
    },
    hideOfferError: (state) => {
      return { ...state, offerError: '' }
    },
    showGetOfficesError: (state, action) => {
      return { ...state, getOfficesError: action.payload }
    },
    hideGetOfficesError: (state) => {
      return { ...state, getOfficesError: '' }
    },
    showGetDatesError: (state, action) => {
      return { ...state, getDatesError: action.payload }
    },
    hideGetDatesError: (state) => {
      return { ...state, getDatesError: '' }
    },
    showGetTimesError: (state, action) => {
      return { ...state, getTimesError: action.payload }
    },
    hideGetTimesError: (state) => {
      return { ...state, getTimesError: '' }
    },
    showResetPasswordError: (state, action) => {
      return { ...state, resetPassword: action.payload }
    },
    hideResetPasswordError: (state) => {
      return { ...state, resetPassword: '' }
    },
    showGetItemParamsError: (state, action) => {
      return { ...state, getItemParamsError: action.payload }
    },
    hideGetItemParamsError: (state) => {
      return { ...state, getItemParamsError: '' }
    },
    showCreateUserError: (state, action) => {
      return { ...state, createUserError: action.payload }
    },
    hideCreateUserError: (state) => {
      return { ...state, createUserError: '' }
    },
    showUpdateUserError: (state, action) => {
      return { ...state, updateUserError: action.payload }
    },
    hideUpdateUserError: (state) => {
      return { ...state, updateUserError: '' }
    },
    showProfileResetPassError: (state, action) => {
      return { ...state, profileResetPassError: action.payload }
    },
    hideProfileResetPassError: (state) => {
      return { ...state, profileResetPassError: '' }
    },
    showShippingKitCheckInError: (state, action) => {
      return { ...state, shippingKitCheckInError: action.payload }
    },
    hideShippingKitCheckInError: (state) => {
      return { ...state, shippingKitCheckInError: '' }
    },
    showBulkError: (state, action) => {
      return { ...state, bulkError: action.payload }
    },
    hideBulkError: (state) => {
      return { ...state, bulkError: '' }
    },
    showAppointmentItemsError: (state, action) => {
      return { ...state, appointmentItemsError: action.payload }
    },
    hideAppointmentItemsError: (state) => {
      return { ...state, appointmentItemsError: '' }
    },
    showGetDealError: (state, action) => {
      return { ...state, getDealError: action.payload }
    },
    hideGetDealError: (state) => {
      return { ...state, getDealError: '' }
    },
    showGetProfileParamsError: (state, action) => {
      return { ...state, getProfileParamsError: action.payload }
    },
    hideGetProfileParamsError: (state) => {
      return { ...state, getProfileParamsError: '' }
    },
  },
})

export const {
  showOtherError,
  hideOtherError,
  showEmailError,
  hideEmailError,
  showLoginError,
  hideLoginError,
  showPhoneError,
  hidePhoneError,
  showCodeError,
  hideCodeError,
  showAppointmentError,
  hideAppointmentError,
  showOfferError,
  hideOfferError,
  showGetOfficesError,
  hideGetOfficesError,
  showGetTimesError,
  hideGetTimesError,
  showGetDatesError,
  hideGetDatesError,
  showResetPasswordError,
  hideResetPasswordError,
  showGetItemParamsError,
  hideGetItemParamsError,
  showCreateUserError,
  hideCreateUserError,
  showUpdateUserError,
  hideUpdateUserError,
  showProfileResetPassError,
  hideProfileResetPassError,
  showShippingKitCheckInError,
  hideShippingKitCheckInError,
  showBulkError,
  hideBulkError,
  showAppointmentItemsError,
  hideAppointmentItemsError,
  showGetDealError,
  hideGetDealError,
  showGetProfileParamsError,
  hideGetProfileParamsError,
} = errors.actions

export default errors.reducer
