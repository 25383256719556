import axios, { AxiosPromise } from 'axios'
import { API_URL, API_URL_V2, LOCAL_ACCESS_TOKEN, LOCAL_ID_TOKEN, LOCAL_USER } from './consts'
import { navigate } from 'gatsby-plugin-intl'

interface Props {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE'
  url: string
  data?: Object | null
  isFormData?: boolean
  v2?: boolean
  params?: object
  signal?: any
}

export const callApi = async ({
  method,
  url,
  data = null,
  isFormData,
  v2,
  params,
  signal,
}: Props): Promise<AxiosPromise<any>> => {
  try {
    let sendData: any = data

    if (isFormData) {
      sendData = new FormData()
      sendData.append('data', JSON.stringify(data))
    }

    const currentLang = window.location.pathname.includes('/es/') ? 'es' : 'en'

    let headers = {
      'Content-Type': isFormData ? 'multipart/form-data ' : 'application/json',
      Authorization: '',
      'Accept-Language': currentLang,
    }

    const token = localStorage.getItem(LOCAL_ID_TOKEN)

    if (token) {
      headers = { ...headers, Authorization: `Bearer ${token}` }
    }

    let api_uri = v2 ? API_URL_V2 : API_URL

    return await axios({
      method: method,
      url: api_uri + url,
      data: sendData,
      headers,
      params,
      signal,
    })
  } catch (e: any) {
    console.log('-> e', e)
    if (e?.response?.status === 401 && window.location.href?.includes('appointment-confirmation')) {
      // @ts-ignore
      return console.log(e);
    }
    if (e?.response?.status === 401 && !window.location.href?.includes('login')) {
      if (window.location.href?.includes('my-account')) {
        sessionStorage.setItem('fromLocation', JSON.stringify(window.location))
        localStorage.removeItem(LOCAL_USER)
        localStorage.removeItem(LOCAL_ID_TOKEN)
        localStorage.removeItem(LOCAL_ACCESS_TOKEN)
        localStorage.setItem('isLoggedIn', String(false))
        // @ts-ignore
        return navigate('/login')
      } else {
        localStorage.setItem('shouldCleanUser', String(true))
        localStorage.removeItem(LOCAL_USER)
        localStorage.removeItem(LOCAL_ID_TOKEN)
        localStorage.removeItem(LOCAL_ACCESS_TOKEN)
        localStorage.setItem('isLoggedIn', String(false))
        // @ts-ignore
        return navigate('/')
      }
    }
    if (e?.response?.status === 401 && window.location.href?.includes('login')) {
      // @ts-ignore
      return console.log(e);
    }
    if (e?.response && e.response?.data && e.response.data?.message) throw e.response.data.message
    else if (e?.response && e.response?.data && e.response.data?.error) throw e.response.data.error
    else throw e?.message
  }
}
